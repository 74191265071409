export default {
  data() {
    return {
      teamValue: null,
      selMem: [],
      ignoreStoreSchema: false,
    };
  },
  mounted() {
    if (this.$route.query.teamId) {
      this.teamValue = Number.parseInt(this.$route.query.teamId);
      this.ignoreStoreSchema = true;
    }
    if (this.$route.query.userId) {
      var user = {
        UsId: Number.parseInt(this.$route.query.userId),
        Identity: this.$route.query.identity,
      };
      this.selMem.push(user);
      this.ignoreStoreSchema = true;
    }
  },
};
