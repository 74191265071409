//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import teamOptions from "@/mixins/teamOptions";
export default {
  mixins: [teamOptions],
  components: {
    Header: () => import("@/components/Header"),
    TaskList: () => import("./task-list"),
    // MemberTask: () => import("./member-task"),
  },
  data() {
    return {
      functionName: "任务管理",
      tabType: 1,
      tabList: [
        {
          value: 1,
          label: "我的任务",
        },
        {
          value: 2,
          label: "成员任务",
        },
      ],
    };
  },
  methods: {
    /**
     * tab视图切换
     */
    tabChange(val) {
      this.tabType = val;
    },
  },
};
