import { mapState } from "vuex";
import MixinQueryString from "@/mixins/queryString";

export default {
  mixins: [MixinQueryString],
  data: () => ({
    teamOptions: [],
    teamValue: null,
    defaultTeamValue: null,
    needLoadData: false,
    functionName: null,
  }),
  computed: {
    ...mapState(["allTeams", "user"]),
    team() {
      if (this.teamOptions && this.teamValue) {
        return this.teamOptions.find((m) => m.Id == this.teamValue);
      }
    },
    isLocalStorage() {
      return this.team && this.team.IsEnableLocalStore;
    },
  },
  watch: {
    allTeams() {
      this.getTeamOptions();
    },
    user() {
      if (this.user && this.WebsocketConnected && this.needLoadData) {
        this.$nextTick(() => {
          this.teamMixinGetData();
        });
        this.needLoadData = false;
      }
    },
    "user.DefaultTeamId"() {
      this.defaultTeamValue = this.user.DefaultTeamId;
      if (this.teamOptions.length > 0 && this.defaultTeamValue && !this.teamValue) {
        var team = this.teamOptions.find((m) => m.Id == this.defaultTeamValue);
        if (team) {
          this.teamValue = team.Id;
        }
      }
    },
    teamValue() {
      this.$nextTick(() => {
        this.teamMixinGetData();
      });
    },
    WebsocketConnected() {
      if (this.user && this.WebsocketConnected && this.needLoadData) {
        this.$nextTick(() => {
          this.teamMixinGetData();
        });
        this.needLoadData = false;
      }
    },
  },
  methods: {
    getDefaultTeamId() {
      if (this.user && this.user.DefaultTeamId) {
        this.defaultTeamValue = this.user.DefaultTeamId;
      }
    },
    getTeamOptions() {
      if (this.allTeams.length <= 0) return;
      if (this.teamOptions.length > 0) return;

      this.teamOptions = this.allTeams;
      if (this.functionName) {
        this.teamOptions = this.teamOptions.filter((m) => m.Functions.indexOf(this.functionName) != -1);
      }
      if (this.teamOptions.length > 0 && this.defaultTeamValue && !this.teamValue) {
        var team = this.teamOptions.find((m) => m.Id == this.defaultTeamValue);
        if (team) {
          this.teamValue = team.Id;
        }
      }
    },
    teamMixinGetData() {
      if (!this.teamValue) {
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0 && !this.ignoreStoreSchema) {
        return;
      }
      // if (!this.ignoreStoreSchema && this.isLocalStorage) {
      //   return;
      // }
      if (this.AllowWebrtc && (!this.user || !this.WebsocketConnected)) {
        this.needLoadData = true;
      } else {
        this.handleGetData?.();
      }
    },
  },
  mounted() {
    this.getDefaultTeamId();
    this.getTeamOptions();
  },
};
